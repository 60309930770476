import { ScrollDispatcher } from '@angular/cdk/overlay';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { _isDev, _log } from '@shared/aux_helper_environment';
import {
  _cloneDeep,
  _containNormaliceStrings,
  _debounceDecorator,
  _equal,
  _getNewNumberId,
  _orderBy,
  _timeout,
} from '@shared/aux_helper_functions';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export const mapIdCero = (list, reverse = false, arrayOfNumbers = false, id = 'id') => {
  if (!list) return [];
  //const replacer = 'P4Mth25rQyB';
  const replacer = -1;
  const find = !reverse ? 0 : replacer;
  const value = !reverse ? replacer : 0;
  if (!arrayOfNumbers) return list.map(t => (t[id] === find ? { ...t, [id]: value } : t));
  if (arrayOfNumbers) return list.map(t => (t === find ? value : t));
};
@Component({
  selector: 'generic-lookup',
  templateUrl: './generic-lookup.component.html',
  styleUrls: ['./generic-lookup.component.scss'],
})
export class GenericLookupComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input('multiple') multiple = true;
  @Input('values') parentValues: any[] = null;
  @Input('selected') parentSelected: any[] = [];

  @Input('visibleTagsValues') visibleTagsValues: any[] = null; //igual que parentValues pero filtrado, cuando no viene en null se usa este.

  /**/
  @Input('id') rootId: string = 'lookUp' + _getNewNumberId();
  @Input('placeholder') placeholder: string = null;
  @Input('placeholderLookup') placeholderLookup: string = this.translate.instant('GENERIC_COMP.LOOKUP.Search');
  @Input('required') required = false;
  @Input('disabled') disabled = false;
  @Input('readOnly') readOnly = false;
  @Input('asyncFlag') asyncFlag = false;
  @Input('asyncSearch') asyncSearch = false;
  @Input('tabindex') tabindex: number = null;
  @Input('panelClass') panelClass: string = 'panel' + _getNewNumberId();
  @Input('panelClassCustom') panelClassCustom: string = null;
  @Input('customClass') rootCustomClass = '';
  @Input('optCustomClass') optCustomClass: string = null;
  @Input('itemSize') itemSize = 4;
  @Input('optionHeight') optionHeight = 48;
  @Input('showLookup') showLookup = true;
  @Input('showOnlySelectedBtn') showOnlySelectedBtn = true;
  @Input('showOnlySelectedValues') _onlySelected = false;
  @Input('showToolTipValues') showToolTipValues = true;
  @Input('showLoadWhenNoValue') showLoadWhenNoValue = true;
  @Input('showClearAllBtn') showClearAllBtn = true;
  @Input('showSelectAllBtn') showSelectAllBtn = true;
  @Input('showOptionsBtns') showOptionsBtns = true;
  @Input('showItemsNotFound') showItemsNotFound = true;
  @Input('alwaysShowToolTipValues') alwaysShowToolTipValues = false;
  @Input('manualLookUp') manualLookUp = false;
  @Input('showSelectAllGroupBtn') showSelectAllGroupBtn = true;
  @Input('optionComponent') optionComponent: ElementRef = null;
  @Input('addCustomOption') addCustomOption: ElementRef = null;
  @Input('emitChangeOnParentValuesChange') emitChangeOnParentValuesChange = true;
  @Input('verbose') verbose = false;
  @Input('orderOptionsFlag') orderOptionsFlag = true; // ordenar el listado de opciones asc
  @Input('showItemsToolTips') showItemsToolTips = true;
  @Input('disabledOption') disabledOption = false;
  @Input('disabledAllOption') disabledAllOption = false;
  /**/
  @Input('propId') propId = 'id';
  @Input('propValue') propValue = 'value';
  @Input('propCode') propCode = 'code';
  /**/
  @Input('group') isGroup = false;
  @Input('propGroupParentId') parentId = 'parentId';
  @Input('test-id') _dataTestId = null;
  @Input('data-test-id') __dataTestId = null;
  /**/
  @Output() onChange: EventEmitter<any[]> = new EventEmitter();
  @Output() onChangeAsObj: EventEmitter<any[]> = new EventEmitter();
  @Output() openedChange: EventEmitter<boolean> = new EventEmitter();
  @Output() lookUpChange: EventEmitter<string> = new EventEmitter();

  /**/

  //NG 10 -> Sacar static
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  private viewPort: CdkVirtualScrollViewport;

  //NG 10 -> Sacar static
  @ViewChild('matSelect', { static: false })
  readonly matSelect: any;

  @ViewChildren(MatOption)
  private options: QueryList<MatOption>;

  $unsuscribreAll: Subject<void> = new Subject<void>();
  destroyed = false;

  /**/

  _lookup = '';
  _lookupBuffer = '';
  _lookupResults = 0;
  values: any[] = null;
  valuesSize = 0;
  valuesAllChildsIds: any[] = null;
  groupParents: any[] = null;
  selected: any[] = [];
  valuesAndSelected: any[] = [];
  valuesAndSelectedText: string[] = [];
  valuesAndSelectedTextStr = '';
  valuesAndSelectedTextStrTtip = '';
  avoidRender = false;

  _auxContains = _containNormaliceStrings;
  trackByPropId = v => v[this.propId];
  trackByValue = v => v[this.propValue];
  _Array = Array;

  itemsNotFound: string = this.translate.instant('GENERIC_COMP.LOOKUP.itemsNotFound');

  minCharLengthToShowTooltip = 16;

  /**/
  constructor(readonly cd: ChangeDetectorRef, readonly sd: ScrollDispatcher, public translate: IanTranslateService) {}

  ngOnInit(): void {
    this.setCssHeight();
  }

  get dataTestId() {
    return this._dataTestId != null ? String(this._dataTestId).toLowerCase() : null;
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.$unsuscribreAll.next();
    this.$unsuscribreAll.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentSelected && !_equal(changes.parentSelected.previousValue, changes.parentSelected.currentValue)) {
      let tmpSelect = this.parentSelected;
      if (!Array.isArray(tmpSelect)) tmpSelect = [tmpSelect];
      this.selected = [...new Set(tmpSelect.map(v => (v ? (typeof v === 'object' ? v[this.propId] : v) : null)).filter(v => v !== null))];
      this.checkOptions();
      this.setValuesAndSelected();
    }

    if (changes.parentValues && this.parentValues !== null && !_equal(changes.parentValues.previousValue, this.parentValues)) {
      const values = this.parentValues ? _cloneDeep([...new Set(this.parentValues)]) : null;
      this.values = this.orderOptionsFlag ? _orderBy(values, [this.propValue]) : values;
      this.valuesSize = this.values ? this.values.length : 0;
      if (this.isGroup && this.values && this.valuesSize) this.fillGroupValues();

      this.checkOptions();
      this.setValuesAndSelected();
      if (this.emitChangeOnParentValuesChange) this.fireOnSelectionChanged();
    }
    //DES-2461 - remplazo el listado de values para el combo, por uno que viene filtrado del padre, pero conservo el original
    if (!_equal(changes.visibleTagsValues?.previousValue, changes.visibleTagsValues?.currentValue)) {
      if (this.visibleTagsValues !== null) {
        this.setValues('visibleTagsValues');
      } else {
        this.setValues();
      }
    }

    if (!_isDev()) this.verbose = false;
  }

  setValues(valueKeys = 'parentValues') {
    const values = this[valueKeys] ? _cloneDeep([...new Set(this[valueKeys])]) : null;
    this.values = this.orderOptionsFlag ? _orderBy(values, [this.propValue]) : values;
    this.valuesSize = this.values ? this.values.length : 0;
    if (this.isGroup && this.values && this.valuesSize) this.fillGroupValues();

    this.checkOptions();
    this.setValuesAndSelected();
    if (this.emitChangeOnParentValuesChange) this.fireOnSelectionChanged();
  }

  ngAfterViewInit(): void {
    this.sd
      .scrolled()
      .pipe(
        takeUntil(this.$unsuscribreAll),
        filter(scrollable => this.viewPort === scrollable)
      )
      .subscribe(() => {
        this.checkOptions();
      });
  }
  /**/

  reserLookupResults() {
    this._lookupResults = 0;
    return true;
  }

  incrementLookupResults() {
    this._lookupResults++;
    return true;
  }

  setLookUp(str) {
    if (false) _log(str, this._lookup);
    if (str == null) return;
    this._lookupBuffer = str;
    if (str.length === 0 || str.length > 1) this.setLookUpSearchStr(str);
    if (str.length === 0) this.resetLookUp();
  }

  resetLookUp_OLD() {
    if (this._lookup === '' && this._lookupBuffer === '') return;
    this._lookup = '';
    this._lookupBuffer = '';
    this.cd && !this.destroyed && this.cd.markForCheck();
    this.lookUpChange.emit('');
  }

  resetLookUp(force = false) {
    if (this._lookup === '' && this._lookupBuffer === '' && !force) return;

    this._lookup = '';
    this._lookupBuffer = '';

    this.lookUpChange.emit('');

    setTimeout(() => {
      // DES-2318 > Se agregó la posibilidad de siempre elegir todos los hijos de un grupo padre, si esto se hacía con
      // una búsqueda de lookup al hacer el reset no actualizaba automáticamente el estado del listado por lo que se fuerza
      this.refreshRenderViewPort();
    }, 0);
  }

  async toggleOnlySelected() {
    this._onlySelected = !this._onlySelected;
    if (!this.selected.length || !this.multiple) this._onlySelected = false;
    this.resetLookUp();
    await this.refreshRenderViewPort();
  }

  async refreshRenderViewPort() {
    if (false) {
      this.avoidRender = true;

      await _timeout(0);
      this.avoidRender = false;

      await _timeout(0);
    }
    this.checkOptions();
  }

  @_debounceDecorator(64)
  async setLookUpSearchStr(str) {
    if (this._lookup === str) return;
    if (this.manualLookUp) {
      this._lookup = '';
    } else {
      this._lookup = str;
    }

    this.cd && !this.destroyed && this.cd.markForCheck();
    this.lookUpChange.emit(str);

    if (false) _log('[setLookUpSearchStr]', str, this._lookup);
  }

  getParentById(parendId) {
    if (!this.isGroup || parendId == null || !this.groupParents || !this.groupParents.length) return null;
    return this.groupParents.find(p => String(p[this.propId]) === String(parendId));
  }

  fillGroupValues() {
    if (!this.isGroup) return;

    this.groupParents = [];
    this.valuesAllChildsIds = [];

    //Inserta padres
    for (let i = 0; i < this.values.length; i++) {
      const el = this.values[i];
      if (el && el[this.propId] != null && el[this.propValue]) {
        const _el = _cloneDeep(el);
        _el._childs = [];
        _el._childsIds = [];
        _el._searchField = _el[this.propValue] + '/';
        delete _el[this.parentId];
        this.groupParents.push(_cloneDeep(_el));
      }
    }

    let _valuesSize = 0;

    //Inserta hijos
    for (let i = 0; i < this.values.length; i++) {
      const el = this.values[i];
      const parendId = el[this.parentId];
      if (el && el[this.propId] != null && parendId != null && el[this.propValue]) {
        const parent = this.getParentById(parendId);
        if (parent && parent._childs) {
          const _el = _cloneDeep(el);
          parent._searchField += _el[this.propValue] + '/';
          _el._searchField = _el[this.propValue] + '/' + parent[this.propValue];
          parent._childs.push(_el);
          this.valuesAllChildsIds.push(_el[this.propId]);
          parent._childsIds.push(_el[this.propId]);
          _valuesSize++;
        }
      }
    }

    const groupParents = this.groupParents.filter(el => el._childsIds && el._childsIds.length > 0);
    this.groupParents = this.orderOptionsFlag ? _orderBy(groupParents, [this.propValue]) : groupParents;
    this.valuesSize = _valuesSize;

    if (true && this.verbose) _log('[fillGroupValues]', this.groupParents);
  }

  parentHasSomeChildSelected(parent) {
    if (!parent || !parent._childs || !parent._childs.length) return false;
    return parent._childs.reduce((acc, option) => {
      if (this.selected.indexOf(option[this.propId]) > -1) acc = true;
      return acc;
    }, false);
  }

  openChange($event: boolean) {
    this.resetLookUp();
    if ($event) {
      this.viewPort.scrollToIndex(0);
      this.viewPort.checkViewportSize();
    }
    this.openedChange.emit($event);
    this.delayCheckOptions();
  }

  setCssHeight() {
    if (!document) return;
    const size = Number(this.itemSize) + Number(this.showLookup ? 1 : 0) + Number(this.addCustomOption ? 1 : 0);
    const panelClass = '.' + this.panelClass;
    const textCss = `${panelClass} {
      max-height: calc(${size} * 48px) !important;
      height: calc(${size} * 48px) !important;
    }`;
    let styles = document.getElementsByTagName('style');
    if (styles && styles[0]) styles[0].append(textCss);
  }

  protected setValuesAndSelected() {
    this.valuesAndSelected =
      this.selected && this.selected.length
        ? [...new Set((this.parentValues || []).filter(el => el && this.selected && (this.selected || []).includes(el[this.propId])))]
        : [];

    this.valuesAndSelectedText = this.valuesAndSelected.map(el => {
      let value = '';
      value += el[this.propCode] ? `${el[this.propCode]} | ` : '';
      value += el[this.propValue];
      return value;
    });
    this.valuesAndSelectedTextStr = this.valuesAndSelectedText.join(', ');

    if (!this.selected.length && !this.readOnly) this._onlySelected = false;

    /*Info para el toolTip*/
    this.valuesAndSelectedTextStrTtip = null;
    if (this.multiple && this.valuesSize > 1 && this.valuesAndSelected.length === this.valuesSize) {
      this.valuesAndSelectedTextStrTtip = this.translate.instant('GENERIC_COMP.LOOKUP.allItemsSelected');
    } else if (!this.valuesAndSelected || this.valuesAndSelected.length === 0) {
      this.valuesAndSelectedTextStrTtip = this.translate.instant('GENERIC_COMP.LOOKUP.noneItemsSelected');
    } else {
      this.valuesAndSelectedTextStrTtip = this.valuesAndSelectedText
        ? (this.multiple
            ? this.translate.instant('GENERIC_COMP.LOOKUP.selectedItemsLabel', this.valuesAndSelectedText.length) + '\n'
            : '') +
          this.valuesAndSelectedText.slice(0, 20).join('\n') +
          (this.valuesAndSelectedText.length > 20 ? '...\n[+' + (this.valuesAndSelectedText.length - 20) + ']' : '')
        : null;
    }
  }

  protected fireOnSelectionChanged() {
    this.setValuesAndSelected();

    const parentSelected = this.valuesAndSelected.map(el => el[this.propId]);
    const parentSelectedValues = _cloneDeep(this.valuesAndSelected);

    if (this.verbose) _log('[onSelectionChanged]', { parentSelected, parentSelectedValues });
    this.onChange.emit(parentSelected);
    this.onChangeAsObj.emit(parentSelectedValues);
  }

  protected async onSelectionChange(change, option) {
    if (!change || !change.isUserInput || this.destroyed) return;
    if (this.readOnly) return;

    const value = change.source.value;

    if (this.multiple) {
      const idx = this.selected.indexOf(value);
      if (idx > -1) {
        this.selected.splice(idx, 1);
      } else {
        this.selected.push(value);
      }
    } else {
      this.selected = [value];
    }

    if (this._onlySelected) await this.refreshRenderViewPort();

    this.fireOnSelectionChanged();
  }

  private checkOptions(): void {
    if (!this.options) return;

    this.options.forEach(option => {
      const selected = this.selected.includes(option.value);
      if (selected && !option.selected) {
        option.select();
      } else if (option.selected && !selected) {
        option.deselect();
      }
    });

    this.cd && this.cd.markForCheck();
  }

  protected delayCheckOptions() {
    setTimeout(() => {
      if (!this.destroyed) this.checkOptions();
    });
  }

  selectAll() {
    this.selected = _cloneDeep(this.valuesAllChildsIds ? this.valuesAllChildsIds : (this.values || []).map(el => el && el[this.propId]));
    this.__afterManualSelect();
  }

  selectAllByQuery(query?) {
    if (!(query?.length > 0)) return this.selectAll();

    let selectedTmp = (this.values || [])
      .filter(v => {
        return _containNormaliceStrings(v?.[this.propValue], query);
      })
      .map(el => el && el[this.propId]);

    if (selectedTmp?.length > 0) {
      this.selected = [...new Set([...this.selected, ...selectedTmp])];

      this.__afterManualSelect();
    }
  }

  selectSome(ids: any[]) {
    if (!ids || !ids.length) return;
    this.selected = [...new Set([...this.selected, ...ids])];
    this.__afterManualSelect();
  }

  clearAll() {
    this.selected = [];
    this.__afterManualSelect();
  }

  clearAllByQuery(query?) {
    if (!(query?.length > 0)) return this.clearAll();

    let selectedTmp = (this.values || [])
      .filter(v => {
        return _containNormaliceStrings(v?.[this.propValue], query);
      })
      .map(el => el && el[this.propId]);

    if (selectedTmp?.length > 0) {
      this.selected = this.selected.filter(s => {
        if (selectedTmp.includes(s)) return false;
        return true;
      });

      this.__afterManualSelect();
    }
  }

  async __afterManualSelect() {
    this.fireOnSelectionChanged();
    await this.refreshRenderViewPort();
  }

  canShowSelectAllGroup(optionGrp) {
    // DES-2318 > Si showSelectAllGroupBtn se fuerza a mostrar el multiple sin importar el _lookupBuffer?.length
    if (optionGrp && this.showSelectAllGroupBtn && !this.readOnly && this.multiple && !this._onlySelected) return true;

    if (!optionGrp || !this.showSelectAllGroupBtn || this.readOnly || !this.multiple || this._onlySelected || this._lookupBuffer?.length) {
      return false;
    }

    const childs = optionGrp._childsIds;
    if (!childs || !childs.length) return false;

    let allChildSelected = childs.reduce((acc, c) => {
      if (!(this.selected || []).includes(c)) acc = false;
      return acc;
    }, true);

    return !allChildSelected;
  }

  canShowToolTipValues() {
    return (
      this.valuesAndSelectedTextStrTtip &&
      ((this.showToolTipValues && this.valuesAndSelected && this.valuesAndSelected.length > 1 && this.values && this.values.length) ||
        this.alwaysShowToolTipValues)
    );
  }

  protected onSlectdummyFocus(ev) {
    if (!this.matSelect) return;
    this.matSelect.open();
    this.matSelect.focus();
  }
}
