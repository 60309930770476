import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { _error, _isDev, _isLab, _isPocoxxogas, _log } from '@shared/aux_helper_environment';
import { _timeout } from '@shared/aux_helper_functions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// CORE-40 > Chateá con tus datos
// Ejemplo: Quiero un listado en forma de tabla del ranking de los 5 productos con más venta histórica
//          perfecto, puedes hacer un gráfico de torta con esos mismos datos?

@Component({
  selector: 'quick-panel-data-ai',
  template: `
    <div fxLayout="column" fxFlex class="quick-panel-data-ai-wrapp" *ngIf="firstOpenedPanel">
      <mat-list class="date" cdkFocusRegionStart>
        <mat-icon (click)="closeQuickPanel()" class="closeQuickPanel">close</mat-icon>
        <div
          class="status-indicator"
          [ngClass]="{
            'status-ok': genieOk,
            'status-dummy': genieUseDummyData,
            'status-error': !genieOk
          }"
        ></div>
        <h3 matSubheader class="notification-title">
          <ai-icon [svgStyle]="'width: 28px; height: 28px; padding-top: 2px; padding-right: 4px'"></ai-icon> {{ 'Conversá con tus datos' }}
        </h3>
      </mat-list>

      <div class="chat-container">
        <div class="messages" fxFlex #messagesContainer>
          <div
            *ngFor="let message of messages"
            [ngClass]="{ 'from-user': message.from === 'user', 'from-ai': message.from === 'ai' }"
            class="message"
          >
            <div class="message-header">
              <ng-container *ngIf="message.from !== 'user'"
                ><ai-icon [svgStyle]="'width: 20px; height: 20px; vertical-align: bottom; padding-right: 0px;'"></ai-icon>
              </ng-container>
              {{ message.from === 'user' ? userDataName || 'Usuario' : 'Prisma Copilot' }}
            </div>
            <div *ngIf="message.text.chart && message.textComplete">
              <canvas
                baseChart
                [datasets]="message.text.chart.datasets"
                [labels]="message.text.chart.labels"
                [options]="message.text.chart.options"
                [legend]="message.text.chart.legend"
                [chartType]="message.text.chart.type"
              >
              </canvas>
            </div>
            <div [innerHTML]="message.text.text || message.text"></div>
          </div>
          <div *ngIf="loading" class="message from-ai loading-message">
            <div class="loading-indicator">
              <div class="loadingWrapp">
                <mat-spinner color="accent" diameter="15"></mat-spinner>
              </div>
              Pensando...
            </div>
          </div>
        </div>
      </div>

      <div class="input-container">
        <input matInput [(ngModel)]="userInput" placeholder="Escribe un mensaje para el asistente..." (keyup.enter)="sendMessage()" />
        <button mat-icon-button (click)="sendMessage()">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./quick-panel-data-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponentAi implements AfterViewChecked, OnInit, OnDestroy {
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;

  messages = [{ from: 'ai', text: '¿En qué puedo ayudarte hoy?' }];
  userInput = '';
  genieOk = false;
  genieUseDummyData = false;
  loading = false;
  apiUrl = null;
  userDataName = null;
  chatPermision = null;
  firstOpenedPanel = false;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private oidcSecurityService: OidcSecurityService,
    private prismaDynamicEnv: PrismaDynamicEnv
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._fuseSidebarService.getSidebar('quickPanelAi').openedChanged.subscribe(data => {
      this.firstOpenPanel();
    });
  }

  isAssistantEnabledAi() {
    return (
      _isDev() ||
      _isLab() ||
      _isPocoxxogas() ||
      this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.blocksEnabled.PRISMA-ASSISTANT-AI-DATA-CHAT-DEMO')
    );
  }

  firstOpenPanel() {
    if (this.firstOpenedPanel) return;

    this.firstOpenedPanel = true;

    this.chatPermision = this.isAssistantEnabledAi();
    if (this.chatPermision !== true) return;

    let apiUrl = this.prismaDynamicEnv.getConf(
      'brandCustomization.featuresEnabled.blocksEnabled.PRISMA-ASSISTANT-AI-DATA-CHAT-DEMO-API-HOST'
    );
    if (apiUrl) this.apiUrl = apiUrl;

    this.oidcSecurityService
      .getUserData()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(userData => {
        if (userData?.name) this.userDataName = userData?.name;
      });

    try {
      this.checkApiStatus();
    } catch (e) {}
  }

  ngAfterViewChecked(): void {
    if (false) this.scrollToBottom();
  }

  async scrollToBottomWhitDelay($time = 100) {
    await _timeout($time);
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    if (!this.firstOpenedPanel) return;

    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  closeQuickPanel(): void {
    if (true) {
      setTimeout(() => {
        this._fuseSidebarService.getSidebar('quickPanelAi') && this._fuseSidebarService.getSidebar('quickPanelAi').close();
      });
    }
  }

  checkApiStatus() {
    return new Promise<boolean>((resolve, reject) => {
      if (this.chatPermision !== true || this.apiUrl == null) {
        this.genieOk = false;
        this.genieUseDummyData = false;
        reject();
      }

      this.http.get(`${this.apiUrl}/status`).subscribe(
        (response: any) => {
          this.genieOk = !!response?.genieOk;
          this.genieUseDummyData = !!response?.useDummyData;
          resolve(true);
          _log('GENIE OK', this.genieOk, response);
        },
        e => {
          this.genieOk = false;
          this.genieUseDummyData = false;
          reject(e);
          _error('GENIE OK', this.genieOk, e);
        }
      );
    });
  }

  async sendMessage() {
    if (this.userInput.trim()) {
      this.messages.push({ from: 'user', text: this.userInput });

      const userQuery = this.userInput;
      this.userInput = '';
      this.loading = true;

      try {
        await this.checkApiStatus();
      } catch (e) {}

      await this.scrollToBottomWhitDelay(16);

      if (this.genieOk) {
        this.http.post(`${this.apiUrl}/postQuery`, { query: userQuery }).subscribe(async (response: any) => {
          this.loading = false;
          if (response.error == null && response.result != null) {
            this.simulateTyping({ text: this.sanitizer.bypassSecurityTrustHtml(response.result) });
          } else {
            this.simulateTyping({ text: 'Error: ' + response.error });
          }
          this.scrollToBottomWhitDelay();
        });
      } else {
        setTimeout(async () => {
          this.mockResponse();
          this.loading = false;
          this.scrollToBottomWhitDelay();
        }, 500);
      }
    }
  }

  _mockResponseLasIndex = 0;
  mockResponse(): void {
    const responses = [
      {
        answer: '¿Ya alcanzamos el objetivo de volumen para Diesel este mes?',
        text: 'Ya cubriste un 70% del objetivo de 50.000 litros de Diesel de este mes. Si las ventas continuan a este ritmo, se va a superar el objetivo llegando al 120% de cumplimiento.',
      },
      {
        answer: '¿El último aumento de precio de Magna tuvo algún impacto en el volumen de Premium?',
        text: 'El volumen total de Premium aumentó un 4% el último mes, esto podría estar relacionado con un aumento fuerte del precio de Magna (+10%) durante el último mes.',
      },
    ];

    const randomResponse = responses[true ? this._mockResponseLasIndex : Math.floor(Math.random() * responses.length)];

    this._mockResponseLasIndex++;
    if (this._mockResponseLasIndex > responses.length - 1) this._mockResponseLasIndex = 0;

    this.simulateTyping(randomResponse);
  }

  simulateTyping(response: any): void {
    let currentText = '';
    const minTypingSpeed = 3; // Minimum typing speed
    const maxTypingSpeed = 40; // Maximum typing speed

    const interval = setInterval(() => {
      if (currentText.length < response.text.length) {
        currentText += response.text.charAt(currentText.length);
        this.updateLastMessage({ ...response, text: currentText, textComplete: false });
      } else {
        clearInterval(interval);
        this.updateLastMessage({ ...response, textComplete: true });
      }
    }, this.getRandomTypingSpeed(minTypingSpeed, maxTypingSpeed));
  }

  getRandomTypingSpeed(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  updateLastMessageNew(response: any): void {
    if (this.messages.length > 0 && this.messages[this.messages.length - 1].from === 'ai') {
      this.messages[this.messages.length - 1] = response;
    } else {
      this.messages.push(response);
    }
  }

  updateLastMessage(text: string): void {
    if (this.messages.length > 0 && this.messages[this.messages.length - 1].from === 'ai') {
      this.messages[this.messages.length - 1].text = text;
    } else {
      this.messages.push({ from: 'ai', text });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
